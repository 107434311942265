.table-row-dark {
  background-color: #fafafa;
}

.insights-page .ant-menu-item:active,
.insights-page .ant-menu-item-selected,
.insights-page .ant-menu-item-selected {
  background: transparent !important;
}

.insights-page .ant-menu-item-selected::after {
  opacity: 0 !important;
}

.insights-page .ant-menu-root.ant-menu-inline,
.insights-card .ant-card {
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

.insights-page .ant-menu-title-content {
  overflow: visible !important;
}

.insights-page .ant-menu-item-selected {
  color: inherit;
}

.insights-page .ant-menu-item-active,
.insights-page .ant-menu-submenu-selected {
  color: rgba(0, 0, 0, 0.85) !important;
}
