.container {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.headerItem {
  flex: 0 1 auto;
}

.contentItem {
  height: 100%;
  flex: 1 1 auto;
  margin-left: auto;
  margin-right: auto;
}

.center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
