.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* no select when double click on bone model */
  user-select: none;
}

.flexContainer {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.formItem {
  margin-bottom: 4px;
}
